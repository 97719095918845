import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../home.css';
import { SvgTeam } from './SvgTeam';
import { useGlobalContext } from './Context';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../firebaseConfig';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SvgRmu } from './SvgRmu';

export default function Handler() {
    const {
        isLoading,
        setIsLoading,
        copy,
        setCopy,
        setPostDone,
        postDone,
        url,
        setUrl,
        selected,
        link,
        setLink,
        landscape,
        setLandscape,
    } = useGlobalContext();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [stats, setStats] = useState([]);
    const [playSite, setPlaySite] = useState(false);
    const compId = searchParams.get('compId');
    const matchId = searchParams.get('matchId');
    const test = searchParams.get('test');
    const local = searchParams.get('local');

    useEffect(() => {
        if (test === '1' || test === 1) {
            setPlaySite(true);
        } else {
            setPlaySite(false);
        }
    }, [test]);

    const liveReq = () => {
        axios
            .post('https://twism.vercel.app/drid', null, {
                params: { matchId, compId },
            })
            .then(response => {
                const res = Object.keys(response.data).map(key => response.data[key]);
                setStats(res);
            })
            .catch(err => console.warn(err));
    };

    const testReq = () => {
        axios
            .post('https://twism.vercel.app/playsite', null, {
                params: { matchId, compId },
            })
            .then(response => {
                const res = Object.keys(response.data).map(key => response.data[key]);
                setStats(res);
            })
            .catch(err => console.warn(err));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (playSite && local === 'false') {
                testReq();
            } else if (!playSite && local === 'false') {
                liveReq();
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [matchId, compId, playSite, local]);

    useEffect(() => {
        const databaseRef = ref(database, `matches/${matchId}/landscape`);

        const unsubscribe = onValue(databaseRef, snapshot => {
            const data = snapshot.val();
            if (data != null) {
            setLandscape(data);
            }
        });

        return () => off(databaseRef, 'value', unsubscribe);
    }, [matchId]);

    useEffect(() => {
        if (local === 'true') {
            const databaseRef = ref(database, `matches/${matchId}`);

            const unsubscribe = onValue(databaseRef, snapshot => {
                const data = snapshot.val();
                setStats(data);
            });

            return () => off(databaseRef, 'value', unsubscribe);
        }
    }, [matchId, local]);

    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        setAnimationClass('container-exit');
        const timeout = setTimeout(() => setAnimationClass('container-enter'), 500); // Matches transition timing

        return () => clearTimeout(timeout);
    }, [landscape]);

    useEffect(() => {
        console.log('Current landscape value:', landscape);
    }, [landscape]);
    

    return (
        <>
            <Helmet>
                <style>
                    {
                        'body { background-image: none; background-color: transparent !important; }'
                    }
                </style>
            </Helmet>
            <div className={`container ${landscape === false ? 'container-3' : 'container-4'} ${animationClass}`}>
                {(landscape === false) ? (
                    <SvgTeam />
                ) : (
                    <SvgRmu />
                )}
            </div>
        </>
    );
}
